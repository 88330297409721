import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_CONSTANATS } from '../../../shared/api.constants';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HaQuestionsService {
  apiBaseUrl = environment.serverUrl;
  constructor(private http: HttpClient) {}
  getHarrisonUrl(data): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_GET_HARRISON_URL}`, data);
  }
  getClientIP(): any {
    return this.http.get(environment.fetchIP);
  }
  harrisonResponse(data): any {
    return this.http.post(`${this.apiBaseUrl}/${API_CONSTANATS.STUDENT.STUDENT_HARRISON_RESPONSE}`, data);
  }
}
