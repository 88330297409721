export const ERROR_CONSTANTS = {
  LOGIN: {
    ERRORS: {
      LOGIN_EMAIL: 'Email is required',
      LOGIN_EMAIL_MANDATORY: 'Email must be a valid email address',
      LOGIN_PASSWORD: 'Password is required',
      LOGIN_PASSWORD_LENGHT: 'Password must be at least 6 characters',
      LOGIN_INVALID: 'Invalid Credentials',
    },
  },
  SET_PASSWORD: {
    ERRORS: {
      PASSWORD_REQUIRED: 'Password is required',
      PASSWORD_LENGTH: 'Password must be at least 6 characters',
      CONFIRM_PASSWORD: 'Confirm Password is required',
      PASSWORD_MATCH: 'Passwords must match',
      INVVLID_LINK: 'NOT a valid URL',
      PASSWORD_SET_ALREDY: 'Password already set',
      RECORD_NOT_FOUND: 'Invitation URL is wrong',
    },
  },
  EDIT_PROFILE: {
    ERRORS: {
      ENTER_QUERY: 'Please enter valid issue.',
    },
    SUCCESS: {
      UPDATED: 'Query successfully submitted. You will get response soon.',
    },
  },
};
